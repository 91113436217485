import { ShaderMaterial, TextureLoader } from 'three';
import { ReactThreeFiber, extend } from '@react-three/fiber'; 
import IMAGES from 'assets/images';
import { vertexShader } from './vertex';
import { fragmentShader } from './fragment';

const loader = new TextureLoader();
const material = loader.load(IMAGES.sphereLight);

declare global { /* eslint-disable  @typescript-eslint/no-namespace */
    namespace JSX {
        interface IntrinsicElements {
            noiseMaterial: ReactThreeFiber.MaterialNode<NoiseMaterial, typeof NoiseMaterial>
        }
    }
}

export type SphereUniforms = {
    uTime      : { value : number },
    uAmplitude : { value : number },
    uFrequency : { value : number },
    uOffset    : { value : number },
    uLight     : { value : number },

    uR : { value : number },
    uG : { value : number },
    uB : { value : number },

    uLightMaterial : { value : string },
    uDarkMaterial  : { value : string },
};

export default class NoiseMaterial extends ShaderMaterial
{
    constructor()
    {
        super({
            uniforms : {
                uTime      : { value : 0 },
                uAmplitude : { value : 0 },
                uFrequency : { value : 0 },
                uOffset    : { value : 0 },
                uLight     : { value : 0 },
                
                uR : { value : 35.0 },
                uG : { value : 35.0 },
                uB : { value : 35.0 },

                uPrevR : { value : 35.0 },
                uPrevG : { value : 35.0 },
                uPrevB : { value : 35.0 },

                uMaterial : { value : material }
            },
            vertexShader,
            fragmentShader
        });
    }
}

extend({ NoiseMaterial });