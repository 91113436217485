import React from 'react';
import styled from 'styled-components';
import { ColourTheme, SyncNamespace } from 'core/utilities';
import { useSynchronizedState } from './hooks';
import { About, Scene } from './components';

export const RootPage : React.FunctionComponent = () => 
{
    const [ colour ] = useSynchronizedState<ColourTheme>(SyncNamespace.THEME_COLOUR, ColourTheme.DARK);

    return (
        <Container colour={colour}>
            <Scene />
            <About />
        </Container>
    );
};

const Container = styled.div<{ colour : string; }>`
    overflow-x: hidden;
    height: 100vh;
    background-color: ${({ colour }) => colour};
    transition: background-color 1s linear;
`;