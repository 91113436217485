import React, { useRef, useState } from 'react';
import { useFrame, useThree } from '@react-three/fiber';
import { Mesh } from 'three';
import { SphereUniforms } from './material/noise';
import { useSpring } from '@react-spring/three';
import { useSubscriber, useSynchronizedState } from '../hooks';
import { ColourTheme, SyncNamespace } from 'core/utilities';
import './material/noise';
import GlowEffect from './GlowEffect';

const FREQUENCY = 3;
const AMPLITUDE = 20;

interface SphereProps 
{
    radius    : number;
    position? : number;
}

export const Sphere : React.FunctionComponent<SphereProps> = ({
    radius,
    position
}) =>
{
    const ref = useRef<Mesh & { material : { uniforms : SphereUniforms; } }>();
    const [ time, setTime ] = useState<number>(0);
    // const [ colour, setColour ] = useSynchronizedState<ColourTheme>(SyncNamespace.THEME_COLOUR, ColourTheme.DARK);
    const [ shouldTransition ] = useSynchronizedState<boolean>(SyncNamespace.TOGGLE_FRAG, false);

    const { uLight } = useSpring({
        config: { mass: 1, tension: 10, friction: 10, precision: 0.0001 },
        uLight: shouldTransition ? 1.0 : 0.0,
        // scale : shouldScale ? 0.0 : 1.0
    });

    useFrame(({ 
        mouse, 
        clock 
    }) =>
    {
        if (ref.current)
        {
            // const distance = Math.min(0.8, 0.8 - Math.sqrt(mouse.x ** 2 + mouse.y ** 2));

            // ref.current.material.uniforms.uAmplitude.value = 50 * Math.cos(1 - distance);
            // ref.current.material.uniforms.uFrequency.value = Math.max(0, Math.min(5, FREQUENCY * Math.sin(1 - distance)));
            // // ref.current.material.uniforms.uTime.value = Math.sin(time);
            // ref.current.rotation.x += 0.002;
            // ref.current.rotation.y += 0.002;
            // ref.current.rotation.z += 0.002;

            // if (position) ref.current.position.z = position;

            // setTime(distance * Math.sin(clock.elapsedTime));
            // // setTime(Math.sin(clock.elapsedTime));

            const distance = Math.sqrt(mouse.x ** 2 + mouse.y ** 2);
            ref.current.material.uniforms.uLight.value = Math.sin(uLight.get()) + Math.cos(distance);

            const dt = Math.sin(0.8 * time / 5) + Math.PI; //- Math.cos(mouse.x * mouse.y);

            const dm = Math.cos(Math.sqrt((0.5 - mouse.x) ** 2 + (0.5 - mouse.y) ** 2));

            ref.current.material.uniforms.uAmplitude.value = Math.abs(50 * Math.cos(1 - dt));
            ref.current.material.uniforms.uFrequency.value = Math.max(0.8, Math.min(5, FREQUENCY * Math.sin(1 - dt)));
            ref.current.material.uniforms.uTime.value = Math.sin(time);
            ref.current.rotation.x -= 1 / 100 * mouse.y;
            ref.current.rotation.y += 1 / 100 * mouse.x;

            if (position) ref.current.position.z = position;

            setTime(clock.elapsedTime);
        }
    });

    return (
        <>
            <mesh ref={ref}>
                <icosahedronBufferGeometry args={[ radius, 70 ]}  />
                <noiseMaterial attach="material" />
            </mesh>
        </>
    );
};